.largeScreen {
  /*更改表格颜色*/
}
.largeScreen .el-table tbody tr:hover > td {
  background-color: transparent !important;
}
.largeScreen .el-table,
.largeScreen .el-table__expanded-cell {
  background: transparent;
}
.largeScreen .el-table .cell {
  color: #cecece;
}
.largeScreen .el-table td,
.largeScreen .el-table th.is-leaf {
  border-bottom: none !important;
}
.largeScreen .double {
  background: #141e38 !important;
}
.largeScreen .single {
  background: #03051b !important;
}
.largeScreen .el-dialog {
  background: transparent;
}
.largeScreen .el-dialog__header {
  display: none;
}
.largeScreen .el-dialog__body {
  background-image: url("../../assets/dialogbg.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  padding: 0;
}
.largeScreen .studentspan {
  width: 4rem;
}
